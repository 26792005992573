import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Contact } from 'components/organisms/Contact'
import { News } from 'components/organisms/News'

const AktualnosciPage: React.FC<PageProps<Queries.AktualnosciPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.news
  const PAGE_SEO = data.wpPage?.seo!
  const POPUP = data.wpPage?.TypePage ?? null

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const NEWS =
    data.allWpNewsSingle.nodes?.map((news) => ({
      image: {
        src: news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
          ?.localFile?.childImageSharp?.gatsbyImageData!,
        alt:
          news.newsSingle?.newsSingleGallery?.[0]?.newsSingleGalleryImg
            ?.altText! ?? '',
      },
      heading: news.title!,
      text: news.newsSingle?.newsSingleExcerpt!,
      date: new Date(news.date!).toLocaleDateString(),
      link: `/aktualnosci/${news.slug}`,
    })) || []

  return (
    <Layout
      popup={
        POPUP
          ? {
              image: {
                src: POPUP?.popupImage?.localFile?.childImageSharp
                  ?.gatsbyImageData!,
                alt: POPUP?.popupImage?.altText!,
              },
              active: POPUP?.popupActive!,
              activeMobile: POPUP?.popupActiveMobile!,
              delay: POPUP?.popupDelay!,
              link: POPUP?.popupLink!,
            }
          : undefined
      }
    >
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <News heading={PAGE.newsHeroTitle!} cards={NEWS} />
      <Contact
        heading={PAGE.newsFormsTitle!}
        formTitle={PAGE.newsFormsFormTitle!}
        image={{
          src: PAGE.newsFormsImg?.localFile?.childImageSharp?.gatsbyImageData!,
          alt: PAGE.newsFormsImg?.altText!,
        }}
      />
    </Layout>
  )
}

export default AktualnosciPage

export const query = graphql`
  query AktualnosciPage {
    wpPage(slug: { regex: "/aktualnosci/" }) {
      title
      TypePage {
        popupActive
        popupActiveMobile
        popupDelay
        popupLink
        popupImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
      }
      news {
        newsHeroTitle
        newsFormsTitle
        newsFormsFormTitle
        newsFormsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
    allWpNewsSingle(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        date
        newsSingle {
          newsSingleExcerpt
          newsSingleGallery {
            newsSingleGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
              }
            }
          }
        }
      }
    }
  }
`
